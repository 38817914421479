<template>
  <div class="dataprotectionParent">
    <a
      class="topRightCloseButton"
      v-if="!$store.getters.isAuthenticated"
      v-on:click="goToLogin()"
      >X</a
    >
    <div class="dataprotectionWrapper">
      <h1>{{ $t("dataprotection.dataprotection") }}</h1>
      <div v-html="$t('dataprotection.text')"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dataprotection",
  data() {
    return {};
  },
  methods: {
    goToLogin() {
      this.$router.push(this.$i18nRoute({name: "Login"}));
    },
  },
  mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.dataprotectionParent {
  height: 100%;
  overflow: auto;
}
.dataprotectionWrapper {
  padding: 2em;
}
</style>